import React from 'react';

export const AppFooter = () => {

    return (
        <div className="layout-footer">
            <span className="footer-text" style={{ 'marginRight': '5px' }}>JAFASA TECH CONSULTING SA DE CV &copy;</span>
            {/* <img src="assets/layout/images/logo.svg" alt="Logo" width="80" /> */}
            {/* <span className="footer-text" style={{ 'marginLeft': '5px' }}>TECH CONSULTING SA DE CV</span> */}
        </div>
    );
}
