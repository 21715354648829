import { GET_USER, LOGOUT, SET_COMPANY, SET_LOGIN} from "../types";

export default function LoginReducer(state, action) {
    const { payload, type } = action;

    switch (type) {
        case GET_USER:
            return {
                ...state,
                activeUser: payload,
            };
        case LOGOUT:
            return {
                ...state,
                activeUser: null,
                activeCompany: null,
            };
        case SET_COMPANY:
            return {
                ...state,
                activeCompany: payload,
            };
        case SET_LOGIN:
            return {
                ...state,
                activeLogin: payload,
            };
        default:
            return state;
    }
}
