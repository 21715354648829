import axios from 'axios';
import { restAPI } from '../config/RestApiConfiguration'

export class OperacionService {

    async getOperaciones(idCuenta) {
        const res = await axios.get(`${restAPI.SERVER_IP_ADDR}/cuentas/${idCuenta}/operaciones`);
        return res;
    }

    async createOperacion(idCuenta,files) {
        const res = await axios.post(`${restAPI.SERVER_IP_ADDR}/cuentas/${idCuenta}/operaciones`,files);
        return res;
    }

}