import React, { useState } from 'react';
import classNames from 'classnames';
import { CSSTransition } from 'react-transition-group';
import {useLogin} from './hooks/context/login/LoginContext'
import { Avatar } from 'primereact/avatar';

export const AppProfile = () => {

    const [expanded, setExpanded] = useState(false);


    const onClick = (event) => {
        setExpanded(prevState => !prevState);
        event.preventDefault();
    }
    
    const {activeLogin} = useLogin();
    
    if (activeLogin === null) {
        return (
            <div>
                Still loading!
            </div>
        )
    }
    return (
        <div className="layout-profile">
            <div>
                {/* <Avatar image="https://i.pravatar.cc/300" className="p-mr-2" size="xlarge" shape="circle" /> */}
                {/* <Avatar icon="pi pi-user" image={"data:image/png;base64, " + activeUser.foto} className="p-mr-2" size="xlarge" shape="circle" /> */}
                <img  src={"data:image/png;base64, " + activeLogin.usuario.cuenta.empresa.logotipo} alt="" ></img>
            </div>
            <button className="p-link layout-profile-link p-mt-2" onClick={onClick}>
                <span className="username">{activeLogin.usuario.nombre + " " +activeLogin.usuario.apellidoPaterno}</span>
                {/* <i className="pi pi-fw pi-cog" /> */}
            </button>
            {/* <CSSTransition classNames="p-toggleable-content" timeout={{ enter: 1000, exit: 450 }} in={expanded} unmountOnExit>
                <ul className={classNames({ 'layout-profile-expanded': expanded })}>
                    <li><button type="button" className="p-link"><i className="pi pi-fw pi-user" /><span>Account</span></button></li>
                    <li><button type="button" className="p-link"><i className="pi pi-fw pi-inbox" /><span>Notifications</span><span className="menuitem-badge">2</span></button></li>
                    <li><button type="button" className="p-link"><i className="pi pi-fw pi-power-off" /><span>Logout</span></button></li>
                </ul>
            </CSSTransition> */}
        </div>
    );

}
