import { Card } from "primereact/card";
import { Panel } from "primereact/panel";
import { ProgressBar } from "primereact/progressbar";
import React, { useState, useEffect } from "react";
import { useLogin } from "../hooks/context/login/LoginContext";
import { formatoFecha } from "../utilities/formatoFecha";

export const Dashboard = () => {
    const { activeLogin } = useLogin();


    const titleTemplate = (title) =>{
        return ( 
        <div className="p-d-flex p-jc-center title-template">
            <h2>{title}</h2>
        </div>);
    }

    return (
        <div className="p-grid p-fluid dashboard p-jc-center">
            <div className="p-col-12 p-lg-12 ">
                <Card>

                    <div className="p-d-flex p-jc-center">
                        <h2 style={{ fontWeight: "bold" }}>BIENVENIDO {activeLogin.usuario.nombre.toUpperCase() + " " + activeLogin.usuario.apellidoPaterno.toUpperCase()}</h2>
                    </div>
                </Card>
            </div>
            <div className="p-col-12 p-lg-3">
                <Card className="card summary">
                    <div className="p-col-12 p-d-flex p-jc-center p-ai-center">
                        <span className="title">EXPEDIENTES COMPLETADOS</span>
                        <span className="count aceptado">12</span>  
                    </div>        
                </Card>
            </div>
            <div className="p-col-12 p-lg-3" >
                <Card className="card summary">
                    <div className="p-col-12 p-d-flex p-jc-center p-ai-center">
                        <span className="title">EXPEDIENTES ABIERTOS</span>
                        <span className="count abierto">12</span>  
                    </div>        
                </Card>
            </div>
            <div className="p-col-12 p-lg-3">
            <Card className="card summary">
                    <div className="p-col-12 p-d-flex p-jc-center p-ai-center">
                        <span className="title">EXPEDIENTES EN REVISION</span>
                        <span className="count revision">12</span>  
                    </div>        
                </Card>
            </div>
            <div className="p-col-12 p-lg-3">
            <Card className="card summary">
                    <div className="p-col-12 p-d-flex p-jc-center p-ai-center">
                        <span className="title">EXPEDIENTES CON DETALLES</span>
                        <span className="count detalle">12</span>  
                    </div>        
                </Card>
            </div>

            <div className="p-col-12 p-lg-2">
                <Card title={titleTemplate("VIGENCIA")} className="p-d-flex p-jc-center p-ai-center p-mb-3" style={{ height: "100%" }}>
                    <h2>{formatoFecha(activeLogin.usuario.cuenta.fechaRenovacion)}</h2>
                </Card>
            </div>

            <div className="p-col-12 p-lg-2">
                <Card title={titleTemplate("PLAN")} className="p-d-flex p-jc-center p-ai-center p-mb-3" style={{ height: "100%" }}>
                    <h2>{activeLogin.usuario.cuenta.tipoCuenta.tipoCuenta}</h2>
                </Card>
            </div>

            <div className="p-col-12 p-lg-2">
                <Card title={titleTemplate("USUARIOS")} className="p-d-flex p-jc-center p-ai-center p-mb-3" style={{ height: "100%" }}>
                    <h2 className="p-d-flex p-jc-center p-ai-center p-mb-3">{activeLogin.usuario.cuenta.tipoCuenta.cantidadUsuarios}</h2>
                </Card>
            </div>
            <div className="p-col-12 p-lg-6">
                <Card style={{ height: "100%" }} title={titleTemplate("ALMACENAMIENTO")}>
                    <div className="p-d-flex p-jc-center p-ai-center p-mb-3">
                        {/* <h5 className="activity p-m-0">ALMACENAMIENTO</h5> */}
                        <div className="count">250 MB / 10GB</div>
                    </div>
                    <ProgressBar value={2.5} showValue={true} />
                </Card>
            </div>

            <div className="p-col-12 p-lg-6 contacts">
                <Card title={titleTemplate("MATERIALIDAD")} style={{height:"100%"}}>
                    <p align="justify" style={{fontSize:"18px",fontWeight: "bold"}}>
                        Se refiere a que se acredite y evidencie que, con las operaciones efectuadas y de las cuales se derivó una deducción o acreditamiento, donde el contribuyente obtuvo un beneficio económico cuantificable, existiendo un motivo o necesidad para llevar a cabo dicha operación,
                        siendo esta acorde al fin esencial de la empresa, su giro o cadena productiva.
                    </p>
                </Card>
              
            </div>

            <div className="p-col-12 p-lg-6 contacts">
                 <Card style={{height:"100%"}}>
                    <div className="p-d-flex p-d-fluid p-jc-center p-ai-center" >
                        <img src="assets/layout/images/logoJafasa.png" alt=""  style={{ borderRadius: "0%" }} />
                    </div>
                </Card>
               
            </div>
        </div>
    );
};
