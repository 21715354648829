import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { FileUpload } from "primereact/fileupload";
import { Toolbar } from "primereact/toolbar";

import { InputText } from "primereact/inputtext";
import { ProveedorService } from "../service/ProveedorService";

import DialogProveedor from "../components/DialogProveedor";
import { Dialog } from "primereact/dialog";
import { useLogin } from "../hooks/context/login/LoginContext";
import { formatoFecha } from "../utilities/formatoFecha";

export const Proveedores = () => {
    const proveedorVacio = {
        idProveedor: null,
        nombre: "",
        rfc: "",
        email: "",
        telefono: "",
        lastUpdatedBy: null,
        createdBy: null,
        hasLogin: false,
    };

    const { activeLogin } = useLogin();

    const [loading, setLoading] = useState(false);
    const [submittedLoading, setSubmittedLoading] = useState(false);

    const [proveedores, setProveedores] = useState(null);

    const [proveedorDialog, setProveedorDialog] = useState(false);

    const [deleteProveedorDialog, setDeleteProveedorDialog] = useState(false);
    const [deleteProveedoresDialog, setDeleteProveedoresDialog] = useState(false);

    const [proveedor, setProveedor] = useState(proveedorVacio);

    const [selectedProveedores, setSselectedProveedores] = useState(null);

    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);

    const toast = useRef(null);
    const dt = useRef(null);

    useEffect(() => {
        setLoading(true);
        const proveedorService = new ProveedorService();
        proveedorService.getProveedores(activeLogin.usuario.cuenta.idCuenta).then((res) => {
            if (res.status === 200) {
                setProveedores(res.data);
            } else {
                console.log("Error en la peticion de proveedores.");
            }
            setLoading(false);
        });

        /*     const res = axios.get(`http://192.168.0.17:8080/cuenta/${activeLogin.usuario.cuenta.idCuenta}/proveedores`
          ).then((res) => {

            console.log(res);
            /*const { login,token } = res.data;
            
            setToken(token); 
            setLogin(login);
            setUser(login.usuario);      
            
            });*/
    }, []);

    const openNew = () => {
        setProveedor(proveedorVacio);
        setSubmitted(false);
        setProveedorDialog(true);
    };

    const hideDeleteProveedorDialog = () => {
        setDeleteProveedorDialog(false);
    };

    const hideDeleteProveedoresDialog = () => {
        setDeleteProveedoresDialog(false);
    };

    const editProveedor = (proveedor) => {
        setProveedor({ ...proveedor });
        setProveedorDialog(true);
    };

    const confirmDeleteProduct = (proveedor) => {
        setProveedor(proveedor);
        setDeleteProveedorDialog(true);
    };

    const deleteProveedor = async () => {
        setSubmittedLoading(true);
        const proveedorService = new ProveedorService();
        
        await proveedorService.deleteProveedor(activeLogin.usuario.cuenta.idCuenta, proveedor.idProveedor).then((res) => {
            console.log(res);
            let _proveedores = proveedores.filter((val) => val.idProveedor !== proveedor.idProveedor);
            setProveedores(_proveedores);
            setDeleteProveedorDialog(false);
            setProveedor(proveedorVacio);
            toast.current.show({ severity: "success", summary: "Successful", detail: "Proveedor eliminado", life: 3000 });
        });

        setSubmittedLoading(false);
        console.log("eliminardo");
        
    };


   const findIndexById = (id) => {
        let index = -1;
        for (let i = 0; i < proveedores.length; i++) {
            if (proveedores[i].idProveedor === id) {
                index = i;
                break;
            }
        }

        return index;
    };


    const exportCSV = () => {
        dt.current.exportCSV();
    };

    const confirmDeleteSelected = () => {
        setDeleteProveedoresDialog(true);
    };

    const deleteSelectedProveedores = () => {
        console.log("Eliminados");
    };

    /*  const deleteSelectedProducts = () => {
        let _products = products.filter((val) => !selectedProducts.includes(val));
        setProducts(_products);
        setDeleteProductsDialog(false);
        setSelectedProducts(null);
        toast.current.show({ severity: "success", summary: "Successful", detail: "Products Deleted", life: 3000 });
    };*/

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="Nuevo" style={{background:"#007be5"}} icon="pi pi-plus" className="p-button-success p-mr-2" onClick={openNew} />
                <Button label="Eliminar" icon="pi pi-trash" className="p-button-danger" onClick={confirmDeleteSelected} disabled={!selectedProveedores || !selectedProveedores.length} />
            </React.Fragment>
        );
    };

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <FileUpload mode="basic" style={{background:"#007be5"}} accept="*.xls/" maxFileSize={1000000} label="Importar" chooseLabel="Import" className="p-mr-2 " />
                <Button label="Exportar" icon="pi pi-upload" className="p-button-help" onClick={exportCSV} />
            </React.Fragment>
        );
    };

    const fechaTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Fecha de creacion</span>
                <span className={`product-badge`}>{formatoFecha(rowData.creationDate)}</span>
            </>
        );
    };

    const statusBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Status</span>
                <span className={`product-badge`}>{rowData.estado === "A" ? "Activo" : "Inactivo"}</span>
            </>
        );
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success p-mr-2" onClick={() => editProveedor(rowData)} />
                <Button icon="pi pi-trash" className="p-button-rounded p-button-warning" onClick={() => confirmDeleteProduct(rowData)} />
            </div>
        );
    };

    const header = (
        <div className="table-header p-d-flex p-jc-center">
            <h5 className="p-m-0">Administrar Proveedores</h5>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Buscar..." />
            </span>
        </div>
    );

    const deleteProveedorDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteProveedorDialog} />
            <Button loading={submittedLoading} label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteProveedor} />
        </>
    );
    const deleteProveedoresDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteProveedoresDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteSelectedProveedores} />
        </>
    );

    const  saveProveedor = async () => {
        setSubmitted(true);

        //Datos obligatorios
        if (proveedor.nombre.trim() && proveedor.rfc.trim() && proveedor.email.trim()) {
            
            const proveedorService = new ProveedorService();
            let _proveedores = [...proveedores];
            let _proveedor = {...proveedor};
            setSubmittedLoading(true);
            
            //Actualizar
            if (proveedor.idProveedor) {

                proveedor.lastUpdatedBy = activeLogin.usuario.idUsuario;
                await proveedorService.saveProveedor(activeLogin.usuario.cuenta.idCuenta, proveedor).then((res) => {
                    if (res.status === 200) {
                        const index = findIndexById(proveedor.idProveedor);
                        _proveedores[index] = _proveedor;
                        toast.current.show({ severity: "success", summary: "Successful", detail: "Proveedor actualizado", life: 3000 });
                    }
                    else {
                        console.log("Error en la peticion de edicion proveedores.");
                        toast.current.show({ severity: "error", summary: "Successful", detail: "Proveedor actualizado", life: 3000 });
                    }

                });  
            } 
            //Crear
            else {
                
                proveedor.createdBy = activeLogin.usuario.idUsuario;              
                await proveedorService.createProveedor(activeLogin.usuario.cuenta.idCuenta, proveedor).then((res) => {
                    console.log(res);
                    if (res.status === 200) {
                        //¿Crear login y usuario del nuevo proveedor?
                        if (res.data.hasLogin) {
                            //crear login
                        }

                        toast.current.show({ severity: "success", summary: "Exitoso", detail: "Proveedor creado", life: 3000 });
                        _proveedores.push(res.data);
                       
                    } else {
                        console.log("Error en la peticion de proveedores.");
                        toast.current.show({ severity: "warn", summary: "Atencion", detail: "Proveedor actualizado", life: 3000 });
                    }
                    
                }).catch( error =>  {
                    console.log(error);
                    toast.current.show({ severity: "error", summary: "Error", detail: error.message, life: 3000 });
                } );
            }

            setSubmittedLoading(false);
            setProveedores(_proveedores);
            setProveedor(proveedorVacio);
            setProveedorDialog(false);

        }
    };

    const hideDialog = () => {
        setSubmitted(false);
        setProveedorDialog(false);
    };

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || "";
        let _proveedor = { ...proveedor };
        _proveedor[`${name}`] = val;
        setProveedor(_proveedor);
    };

    return (
        <div className="p-grid crud-demo">
            <div className="p-col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="p-mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>

                    <DataTable
                        ref={dt}
                        value={proveedores}
                        selection={selectedProveedores}
                        onSelectionChange={(e) => setSselectedProveedores(e.value)}
                        dataKey="idProveedor"
                        paginator
                        rows={10}
                        rowsPerPageOptions={[5, 10, 25]}
                        className="datatable-responsive p-datatable-sm"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} proveedores"
                        globalFilter={globalFilter}
                        emptyMessage="No se encontraron proveedores."
                        header={header}
                        resizableColumns
                        columnResizeMode="fit"
                        loading={loading}
                    >
                        <Column selectionMode="multiple" headerStyle={{ width: "3rem" }}></Column>
                        <Column field="nombre" header="Proveedor" sortable></Column>
                        <Column field="estado" header="Estatus" sortable body={statusBodyTemplate}></Column>
                        <Column field="rfc" header="RFC" sortable></Column>
                        <Column field="email" header="Email" sortable></Column>
                        <Column field="telefono" header="Telefono" sortable></Column>
                        <Column field="creationDate" header="Fecha creacion" sortable body={fechaTemplate}></Column>
                        <Column header="Accion" body={actionBodyTemplate}></Column>
                    </DataTable>

                    <DialogProveedor visible={proveedorDialog} proveedor={proveedor} submitted={submitted} submittedLoading={submittedLoading} onInputChange={onInputChange} saveProveedor={saveProveedor} hideDialog={hideDialog} />

                    <Dialog visible={deleteProveedorDialog} style={{ width: "450px" }} header="Confirm" modal footer={deleteProveedorDialogFooter} onHide={hideDeleteProveedorDialog}>
                        <div className="confirmation-content">
                            <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: "2rem" }} />
                            {proveedor && (
                                <span>
                                    ¿Eliminar a <b>{proveedor.nombre}</b>?
                                </span>
                            )}
                        </div>
                    </Dialog>

                    <Dialog visible={deleteProveedoresDialog} style={{ width: "450px" }} header="Confirm" modal footer={deleteProveedoresDialogFooter} onHide={hideDeleteProveedoresDialog}>
                        <div className="confirmation-content">
                            <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: "2rem" }} />
                            {proveedor && <span>¿Eliminar los proveedores seleccionados?</span>}
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    );
};
