import React from 'react';
import '../layout/sass/_loading.scss'

export default function loading() {
  return (
    <div className="Loading">
      <div className="Loading__dot-1" />
      <div className="Loading__dot-2" />
      <div className="Loading__dot-3" />
    </div>
  );
}
