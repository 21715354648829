import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { FileUpload } from "primereact/fileupload";
import { Toolbar } from "primereact/toolbar";

import { InputText } from "primereact/inputtext";

import { Dialog } from "primereact/dialog";
import { UsuarioService } from "../service/UsuarioService";

export const Usuarios = () => {

    const usuarioVacio = {
        "idUsuario" : null,
        "nombre" : "",
        "apellidoPaterno" : "",
        "apellidoMaterno" : "",
        "idCUenta" : null,
        "Status" : ""
    }

    //const [products, setProducts] = useState(null);
    const [proveedores, setProveedores] = useState(null);
    const [usuarios, setUsuarios] = useState(null);

    const [proveedorDialog, setProveedorDialog] = useState(false);
    const [usuarioDialog, setUsuarioDialog] = useState(false);


    //const [productDialog, setProductDialog] = useState(false);
    //const [deleteProductDialog, setDeleteProductDialog] = useState(false);
    //const [deleteProductsDialog, setDeleteProductsDialog] = useState(false);

    //const [product, setProduct] = useState(emptyProduct);
    //const [proveedor, setProveedor] = useState(proveedorVacio);
    const [usuario,setUsuario] = useState(usuarioVacio);

    //const [selectedProducts, setSelectedProducts] = useState(null);
   // const [selectedProveedores, setSselectedProveedores] = useState(null);
    const [selectedUsuarios, setSelectedUsuarios] = useState(null);


    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);

    const toast = useRef(null);
    const dt = useRef(null);

    useEffect(() => {
        const usuarioService = new UsuarioService();
      /*  const productService = new ProductService();
        const proveedorService = new ProveedorService();
        productService.getProducts().then((data) => setProducts(data));
        proveedorService.getProveedores().then((data) => setProveedores(data));*/
        usuarioService.getUsuarios().then( (data) => {setUsuarios(data); console.log(data);});
    }, []);


    const openNew = () => {
        //setProveedor(proveedorVacio);
        setUsuario(usuarioVacio);
        setSubmitted(false);
        setUsuarioDialog(true);
        //setProveedorDialog(true);
    };


    const hideDeleteProductDialog = () => {
        //setDeleteProductDialog(false);
    };

    const hideDeleteProductsDialog = () => {
        //setDeleteProductsDialog(false);
    };



    const editUsuario = (usuarioSel) => {
        setUsuario({ ...usuarioSel });
        setUsuarioDialog(true);
    };

    const confirmDeleteProduct = (product) => {
        //setProduct(product);
        //setDeleteProductDialog(true);
    };

   /* const deleteProduct = () => {
        let _products = products.filter((val) => val.id !== product.id);
        setProducts(_products);
        setDeleteProductDialog(false);
        setProduct(emptyProduct);
        toast.current.show({ severity: "success", summary: "Successful", detail: "Product Deleted", life: 3000 });
    };

    const findIndexById = (id) => {
        let index = -1;
        for (let i = 0; i < products.length; i++) {
            if (products[i].id === id) {
                index = i;
                break;
            }
        }

        return index;
    };

    const createId = () => {
        let id = "";
        let chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        for (let i = 0; i < 5; i++) {
            id += chars.charAt(Math.floor(Math.random() * chars.length));
        }
        return id;
    };*/

    const exportCSV = () => {
        dt.current.exportCSV();
    };

    const confirmDeleteSelected = () => {
       // setDeleteProductsDialog(true);
    };

    const deleteSelectedProducts = () => {
        /*let _products = products.filter((val) => !selectedProducts.includes(val));
        setProducts(_products);
        setDeleteProductsDialog(false);
        setSelectedProducts(null);*/
        toast.current.show({ severity: "success", summary: "Successful", detail: "Products Deleted", life: 3000 });
    };


    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="Nuevo" icon="pi pi-plus" className="p-button-success p-mr-2" onClick={openNew} />
                <Button label="Eliminar" icon="pi pi-trash" className="p-button-danger" onClick={confirmDeleteSelected} disabled={!selectedUsuarios || !selectedUsuarios.length} />
            </React.Fragment>
        );
    };

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <FileUpload mode="basic" accept="image/*" maxFileSize={1000000} label="Importar" chooseLabel="Import" className="p-mr-2 p-d-inline-block" />
                <Button label="Exportar" icon="pi pi-upload" className="p-button-help" onClick={exportCSV} />
            </React.Fragment>
        );
    };

    const codeBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Code</span>
                {rowData.id}
            </>
        );
    };

    const nameBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Name</span>
                {rowData.nombre}
            </>
        );
    };

    const statusBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Status</span>
                <span className={`product-badge`}>{rowData.estado === "A" ? "Activo" : "Inactivo"}</span>
            </>
        );
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success p-mr-2" onClick={() => editUsuario(rowData)} />
                <Button icon="pi pi-trash" className="p-button-rounded p-button-warning" onClick={() => confirmDeleteProduct(rowData)} />
            </div>
        );
    };

    const header = (
        <div className="table-header p-d-flex p-jc-center">
            <h5 className="p-m-0">Administrar Proveedores</h5>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Buscar..." />
            </span>
        </div>
    );

    const deleteProductDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteProductDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text"  />
        </>
    );
    const deleteProductsDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteProductsDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteSelectedProducts} />
        </>
    );


    const saveProduct = () => {
        setSubmitted(true);
/*
        if (product.name.trim()) {
            let _products = [...products];
            let _product = { ...product };
            if (product.id) {
                const index = findIndexById(product.id);

                _products[index] = _product;
                toast.current.show({ severity: "success", summary: "Successful", detail: "Product Updated", life: 3000 });
            } else {
                _product.id = createId();
                _product.image = "product-placeholder.svg";
                _products.push(_product);
                toast.current.show({ severity: "success", summary: "Successful", detail: "Product Created", life: 3000 });
            }

            setProducts(_products);
            setProductDialog(false);
            setProduct(emptyProduct);
        }*/
    };

    const hideDialog = () => {
        setSubmitted(false);
        setUsuarioDialog(false);
    };


    const usuarioDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={saveProduct} />
        </>
    );
    
    const onInputChange = (e, name) => {
        /*const val = (e.target && e.target.value) || "";
        let _proveedor = { ...proveedor };
        _proveedor[`${name}`] = val;
        setProveedor(_proveedor);*/
    };

    const onInputNumberChange = (e, name) => {
       /* const val = e.value || 0;
        let _product = { ...product };
        _product[`${name}`] = val;

        setProduct(_product);*/
    }


    return (
        <div className="p-grid crud-demo">
            <div className="p-col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="p-mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>

                    <DataTable
                        ref={dt}
                        value={usuarios}
                        selection={selectedUsuarios}
                        onSelectionChange={(e) => setSelectedUsuarios(e.value)}
                        dataKey="idUsuario"
                        paginator
                        rows={10}
                        rowsPerPageOptions={[5, 10, 25]}
                        className="datatable-responsive p-datatable-sm"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} proveedores"
                        globalFilter={globalFilter}
                        emptyMessage="No se encontraron proveedores."
                        header={header}
                        resizableColumns
                        columnResizeMode="fit"
                    >
                        <Column selectionMode="multiple" headerStyle={{ width: "3rem" }}></Column>
                        <Column field="nombre" header="Nombre" sortable ></Column>
                        <Column field="creationDate" header="Fecha creacion" sortable ></Column>
                        <Column field="Status" header="Status" sortable ></Column>
                        <Column header="Accion" body={actionBodyTemplate}></Column>
                    </DataTable>

                    <Dialog visible={usuarioDialog} header="Usuario" footer={usuarioDialogFooter} onHide={hideDialog}>

                    </Dialog>                  
{/*                     <Dialog visible={true} style={{ width: '450px' }} header="Confirm" modal footer={deleteProductDialogFooter} onHide={hideDeleteProductDialog}>
                        <div className="confirmation-content">
                            <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                             {product && <span>Are you sure you want to delete <b>{product.name}</b>?</span>} 
                        </div>
                    </Dialog>

                    <Dialog visible={true} style={{ width: '450px' }} header="Confirm" modal footer={deleteProductsDialogFooter} onHide={hideDeleteProductsDialog}>
                        <div className="confirmation-content">
                            <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                             {product && <span>Are you sure you want to delete the selected products?</span>} 
                        </div>
                    </Dialog> */}
                    
                
                
                </div>
            </div>
        </div>
    );
};
