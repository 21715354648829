import React, { useState ,useEffect} from 'react';

import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Password } from 'primereact/password';

// import '. layout/sass/_login.scss'
import '../../layout/sass/_login.scss'
import { useLogin } from '../../hooks/context/login/LoginContext';

export const Login = () => {

    const [username, setUserName] = useState();
    const [password, setPassword] = useState();
    
    const {login} = useLogin();

    const handleSubmit = async (e) => {
        e.preventDefault();
        login(username,password);
    };

    const addClass = (element, className) => {
        if (element.classList) element.classList.add(className);
        else element.className += " " + className;
    };

    useEffect(() => {
        addClass(document.body,'login');
    }, [])

    return (
        <div className="form-demo p-d-flex p-jc-center p-ai-center">
            <div className="">
                <div className="card">
                    <h5 className="p-text-center p-py-2">Iniciar Sesion</h5>
                    <form onSubmit={handleSubmit} className="p-fluid ">
                        <div className="p-field">
                            <span className="p-float-label p-input-icon-right">
                                <i className="pi pi-envelope" />
                                <InputText  id="email" name="email" onChange={(e) => setUserName(e.target.value)} />
                                <label htmlFor="email">Email*</label>
                            </span>
                        </div>
                        <div className="p-field">
                            <span className="p-float-label">
                                <Password  id="password" name="password" onChange={(e) => setPassword(e.target.value)} feedback={false} toggleMask/>
                                <label htmlFor="password">Contraseña*</label>
                            </span>
                        </div>

                        <Button type="submit" label="Entrar" className="p-mt-2" />
                    </form>
                </div>
            </div>
        </div>
    );
};
