import {restAPI} from '../config/RestApiConfiguration'
import axios from 'axios'

export class ProveedorService {
    async getProveedores(idCuenta) {
        const res = await axios.get(`${restAPI.SERVER_IP_ADDR}/cuentas/${idCuenta}/proveedores`);
        return res;
    }

    async createProveedor(idCuenta,proveedor) {
        const res =  await axios.post(`${restAPI.SERVER_IP_ADDR}/cuentas/${idCuenta}/proveedores`,proveedor)
        return res;
    }


    async saveProveedor(idCuenta,proveedor) {
        const res = await axios.put(`${restAPI.SERVER_IP_ADDR}/cuentas/${idCuenta}/proveedores`,proveedor);
        return res;
    }

    async deleteProveedor(idCuenta,idProveedor) {
        const res = await axios.delete(`${restAPI.SERVER_IP_ADDR}/cuentas/${idCuenta}/proveedores/${idProveedor}`);
        return res;
    }
}
