import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { InputSwitch } from "primereact/inputswitch";

import { Button } from "primereact/button";

import classNames from "classnames";

const DialogProveedor = (props) => {
    const { visible, proveedor, submitted, onInputChange, saveProveedor, hideDialog, submittedLoading } = props;
    
    const proveedortDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideDialog} disabled={submittedLoading}/>
            <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={saveProveedor} loading={submittedLoading} />
            
            
        </>
    );

    return (
        <Dialog visible={visible} style={{ width: "450px" }} header="Proveedor" modal className="p-fluid" footer={proveedortDialogFooter} onHide={hideDialog}>
            <div className="p-formgrid p-grid">
                
                <div className="p-field p-col-12">
                    <label htmlFor="nombreProveedor">Razon Social</label>
                    <InputText id="nombreProveedor" value={proveedor.nombre} onChange={(e) => onInputChange(e, "nombre")} required autoFocus className={classNames({ "p-invalid": submitted && !proveedor.nombre })} />
                    {submitted && !proveedor.nombre && <small className="p-invalid">Nombre es obligatorio.</small>}
                </div>

                <div className="p-field p-col-9">
                    <label htmlFor="emailProveedor">Email</label>
                    <InputText id="emailProveedor" value={proveedor.email} onChange={(e) => onInputChange(e, "email")} required  className={classNames({ "p-invalid": submitted && !proveedor.email })} />
                    {submitted && !proveedor.email && <small className="p-invalid">Email es obligatorio.</small>}
                </div>

                <div className=" p-field p-col-3">
                    <label htmlFor="cuentaProveedor" className="p-mr-2">Crear cuenta</label>
                    <InputSwitch inputId="cuentaProveedor" checked={proveedor.hasLogin}  onChange={(e) => onInputChange(e,"hasLogin")}  />
                </div>
       
                <div className="p-field p-col-6">
                    <label htmlFor="rfcProveedor">RFC</label>
                    <InputText maxLength="13" id="rfcProveedor" value={proveedor.rfc} onChange={(e) => onInputChange(e, "rfc")} required  className={classNames({ "p-invalid": submitted && !proveedor.rfc })} />
                    {submitted && !proveedor.rfc && <small className="p-invalid">RFC es obligatorio.</small>}
                </div>
                <div className="p-field p-col-6">
                    <label htmlFor="telefonoProveedor">Celular</label>
                    <InputText id="telefonoProveedor" value={proveedor.telefono} onChange={(e) => onInputChange(e, "telefono")} integeronly />
                </div>

            </div>
        </Dialog>
    );
};

export default DialogProveedor;
