import React, { useRef } from "react";
import { Menu } from "primereact/menu";
import { useLogin } from "./hooks/context/login/LoginContext";

export const AppTopbar = (props) => {
    const menut = useRef(null);
    const { logout, activeCompany, activeLogin } = useLogin();

/*     <div className="p-d-flex p-jc-center">
        <h3>{activeLogin.usuario.cuenta.empresa.razonSocial}</h3>
    </div>
 */
    let items = [
        {
            label: "Usuario",
            items: [
/*                 {
                    label: "Ajustes",
                    icon: "pi pi-fw pi-cog",
                    command: () => {
                        <Switch>
                         <Route path="/" exact component={ConfiguracionUsuario} />
                         </Switch>
                    },
                }, */
                {
                    label: "Cerrar Sesion",
                    icon: "pi pi-fw pi-power-off",
                    command: () => {
                        logout();
                    },
                },
            ],

            
        },
    ];

    const toggleMenu = (event) => {
        menut.current.toggle(event);
    };

    return (
        <div className="layout-topbar clearfix">
            
            <button type="button" className="p-link layout-menu-button" onClick={props.onToggleMenu}>
                    <span className="pi pi-bars" />
            </button>
                
            {activeLogin.usuario.cuenta.empresa.razonSocial && 
                <span className="layout-company-name" style={{fontWeight:"bold",fontSize:"25px"}}>
                    {activeLogin.usuario.cuenta.empresa.razonSocial}
                </span>}

            <div className="layout-topbar-icons">
            
                <button type="button" className="p-link">
                    <Menu ref={menut} model={items} popup id="popup_menu" />
                    <span className="layout-topbar-item-text">User</span>
                    <span className="layout-topbar-icon pi pi-cog p-py-1" onClick={toggleMenu} />
                </button>
            </div>
        </div>
    );
};
