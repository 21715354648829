import React, { useState, useEffect, useRef } from "react";
import classNames from "classnames";

import { BrowserRouter as Router, Switch, useHistory, Route } from "react-router-dom";

import { CSSTransition } from "react-transition-group";

import { AppTopbar } from "./AppTopbar";
import { AppFooter } from "./AppFooter";
import { AppMenu } from "./AppMenu";
import { AppProfile } from "./AppProfile";

import { Dashboard } from "./pages/Dashboard";
import { ButtonDemo } from "./components/ButtonDemo";
import { ChartDemo } from "./components/ChartDemo";
import { Documentation } from "./components/Documentation";
import { FileDemo } from "./components/FileDemo";
import { FloatLabelDemo } from "./components/FloatLabelDemo";
import { FormLayoutDemo } from "./components/FormLayoutDemo";
import { InputDemo } from "./components/InputDemo";
import { ListDemo } from "./components/ListDemo";
import { MenuDemo } from "./components/MenuDemo";
import { MessagesDemo } from "./components/MessagesDemo";
import { MiscDemo } from "./components/MiscDemo";
import { OverlayDemo } from "./components/OverlayDemo";
import { PanelDemo } from "./components/PanelDemo";
import { TableDemo } from "./components/TableDemo";
import { TreeDemo } from "./components/TreeDemo";
import { InvalidStateDemo } from "./components/InvalidStateDemo";

import { Calendar } from "./pages/Calendar";
import { Crud } from "./pages/Crud";
import { EmptyPage } from "./pages/EmptyPage";
import { Proveedores } from './pages/Proveedores'

import { DisplayDemo } from "./utilities/DisplayDemo";
import { ElevationDemo } from "./utilities/ElevationDemo";
import { FlexBoxDemo } from "./utilities/FlexBoxDemo";
import { GridDemo } from "./utilities/GridDemo";
import { IconsDemo } from "./utilities/IconsDemo";
import { SpacingDemo } from "./utilities/SpacingDemo";
import { TextDemo } from "./utilities/TextDemo";
import { TypographyDemo } from "./utilities/TypographyDemo";
import { TimelineDemo } from "./utilities/TimelineDemo";

import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "prismjs/themes/prism-coy.css";
import "@fullcalendar/core/main.css";
import "@fullcalendar/daygrid/main.css";
import "@fullcalendar/timegrid/main.css";
import "./layout/flags/flags.css";
import "./layout/layout.scss";
import "./App.scss";

import { Login } from "./pages/auth/Login";
import { useLogin } from "./hooks/context/login/LoginContext";

import  Loading  from "./components/Loading";
import { Operaciones } from "./pages/Operaciones";
import { Usuarios } from "./pages/Usuarios";
import { ConfiguracionUsuario } from "./pages/ConfiguracionUsuario";

const App = () => {
    const [layoutMode] = useState("static");
    const [layoutColorMode] = useState("light");
    
/*     const [staticMenuInactive, setStaticMenuInactive] = useState(false);
    const [overlayMenuActive, setOverlayMenuActive] = useState(false);
    const [mobileMenuActive, setMobileMenuActive] = useState(false); */
    
    const [inputStyle] = useState("outlined");
    const [ripple, setRipple] = useState(false);
    const [sidebarActive, setSidebarActive] = useState(true);
    const sidebar = useRef();

    const history = useHistory();

    let menuClick = false;

    const { activeLogin } = useLogin();
    

    useEffect(() => {
        if (sidebarActive) {
            addClass(document.body, "body-overflow-hidden");
        } else {
            removeClass(document.body, "body-overflow-hidden");
        }
        
    }, [sidebarActive]);

    const onWrapperClick = () => {
        if (!menuClick  && layoutMode === "overlay") {
            setSidebarActive(false);
        }
        menuClick = false;
    };

    const onToggleMenu = (event) => {
        menuClick = true;

        setSidebarActive((prevState) => !prevState);
        event.preventDefault();
    };

    const onSidebarClick = () => {
        menuClick = true;
    };

    const onMenuItemClick = (event) => {
        if (!event.item.items && layoutMode === "overlay") {
            setSidebarActive(false);
        }
    };

    const menu = [
        { label: "Inicio", icon: "pi pi-fw pi-home", to: "/" },
/*         {
            label: "UI Kit",
            icon: "pi pi-fw pi-sitemap",
            items: [
                { label: "Form Layout", icon: "pi pi-fw pi-id-card", to: "/formlayout" },
                { label: "Input", icon: "pi pi-fw pi-check-square", to: "/input" },
                { label: "Float Label", icon: "pi pi-fw pi-bookmark", to: "/floatlabel" },
                { label: "Invalid State", icon: "pi pi-fw pi-exclamation-circle", to: "/invalidstate" },
                { label: "Button", icon: "pi pi-fw pi-mobile", to: "/button" },
                { label: "Table", icon: "pi pi-fw pi-table", to: "/table" },
                { label: "List", icon: "pi pi-fw pi-list", to: "/list" },
                { label: "Tree", icon: "pi pi-fw pi-share-alt", to: "/tree" },
                { label: "Panel", icon: "pi pi-fw pi-tablet", to: "/panel" },
                { label: "Overlay", icon: "pi pi-fw pi-clone", to: "/overlay" },
                { label: "Menu", icon: "pi pi-fw pi-bars", to: "/menu" },
                { label: "Message", icon: "pi pi-fw pi-comment", to: "/messages" },
                { label: "File", icon: "pi pi-fw pi-file", to: "/file" },
                { label: "Chart", icon: "pi pi-fw pi-chart-bar", to: "/chart" },
                { label: "Misc", icon: "pi pi-fw pi-circle-off", to: "/misc" },
            ],
        }, */
/*         {
            label: "Utilities",
            icon: "pi pi-fw pi-globe",
            items: [
                { label: "Display", icon: "pi pi-fw pi-desktop", to: "/display" },
                { label: "Elevation", icon: "pi pi-fw pi-external-link", to: "/elevation" },
                { label: "Flexbox", icon: "pi pi-fw pi-directions", to: "/flexbox" },
                { label: "Icons", icon: "pi pi-fw pi-search", to: "/icons" },
                { label: "Grid System", icon: "pi pi-fw pi-th-large", to: "/grid" },
                { label: "Spacing", icon: "pi pi-fw pi-arrow-right", to: "/spacing" },
                { label: "Typography", icon: "pi pi-fw pi-align-center", to: "/typography" },
                { label: "Text", icon: "pi pi-fw pi-pencil", to: "/text" },
            ],
        }, */

        {
            label: "Operaciones",
            icon: "pi pi-fw pi-book", to: "/operaciones"
        },
        {
            label: "Proveedores",
            icon: "pi pi-fw pi-users", to: "/proveedores"
        },
        {
            label: "Configuracion",
            icon: "pi pi-fw pi-cog", to: "/configuracionUsuario"
        },
       /*  {
            label: "Auxiliar",
            icon: "pi pi-fw pi-circle-off",
            items: [
                { label: "Crud", icon: "pi pi-fw pi-user-edit", to: "/crud" },
                { label: "Calendar", icon: "pi pi-fw pi-calendar-plus", to: "/calendar" },
                { label: "Timeline", icon: "pi pi-fw pi-calendar", to: "/timeline" },
                { label: "Empty Page", icon: "pi pi-fw pi-circle-off", to: "/empty" },
            ]
        }, */
/*         {
            label: "Menu Hierarchy",
            icon: "pi pi-fw pi-search",
            items: [
                {
                    label: "Submenu 1",
                    icon: "pi pi-fw pi-bookmark",
                    items: [
                        {
                            label: "Submenu 1.1",
                            icon: "pi pi-fw pi-bookmark",
                            items: [
                                { label: "Submenu 1.1.1", icon: "pi pi-fw pi-bookmark" },
                                { label: "Submenu 1.1.2", icon: "pi pi-fw pi-bookmark" },
                                { label: "Submenu 1.1.3", icon: "pi pi-fw pi-bookmark" },
                            ],
                        },
                        {
                            label: "Submenu 1.2",
                            icon: "pi pi-fw pi-bookmark",
                            items: [
                                { label: "Submenu 1.2.1", icon: "pi pi-fw pi-bookmark" },
                                { label: "Submenu 1.2.2", icon: "pi pi-fw pi-bookmark" },
                            ],
                        },
                    ],
                },
                {
                    label: "Submenu 2",
                    icon: "pi pi-fw pi-bookmark",
                    items: [
                        {
                            label: "Submenu 2.1",
                            icon: "pi pi-fw pi-bookmark",
                            items: [
                                { label: "Submenu 2.1.1", icon: "pi pi-fw pi-bookmark" },
                                { label: "Submenu 2.1.2", icon: "pi pi-fw pi-bookmark" },
                                { label: "Submenu 2.1.3", icon: "pi pi-fw pi-bookmark" },
                            ],
                        },
                        {
                            label: "Submenu 2.2",
                            icon: "pi pi-fw pi-bookmark",
                            items: [
                                { label: "Submenu 2.2.1", icon: "pi pi-fw pi-bookmark" },
                                { label: "Submenu 2.2.2", icon: "pi pi-fw pi-bookmark" },
                            ],
                        },
                    ],
                },
            ],
        }, */
     /*   {
            label: "Emision",
            icon: "pi pi-fw pi-file",
            items: [
                {label: "CLientes",icon: "pi pi-fw pi-users"},
                {label: "Proveedores",icon: "pi pi-fw pi-briefcase"}
            ]
        },
        {
            label: "Recepcion",
            icon: "pi pi-fw pi-file",
            items: [
                {label: "CLientes",icon: "pi pi-fw pi-users"},
                {label: "Proveedores",icon: "pi pi-fw pi-briefcase"}
            ]
        },
        {
            label: "Catalogos",
            icon: "pi pi-fw pi-book",
            items: [
                {label: "CLientes",icon: "pi pi-fw pi-users"},
                {label: "Proveedores",icon: "pi pi-fw pi-briefcase"}
            ]
        },*/
        {
            label: "Administrador",
            icon: "pi pi-fw pi-user",
            items: [
                {label: "Usuarios",icon: "pi pi-fw pi-users", to: "/usuarios"},
                {label: "Configuracion de cuenta",icon: "pi pi-fw pi-briefcase"}
            ]
        },
        {
            label: "Super",
            icon: "pi pi-fw pi-key",
            items: [
                {label: "CUentas",icon: "pi pi-fw pi-users"}
            ]
        },
    ];

    const addClass = (element, className) => {
        if (element.classList) element.classList.add(className);
        else element.className += " " + className;
    };

    const removeClass = (element, className) => {
        if (element.classList) element.classList.remove(className);
        else element.className = element.className.replace(new RegExp("(^|\\b)" + className.split(" ").join("|") + "(\\b|$)", "gi"), " ");
    };

    removeClass(document.body,'login');
    
    const isDesktop = () => {
        return window.innerWidth > 1024;
    };

    const isSidebarVisible = () => {
        return sidebarActive;
    };

    

    const logo = layoutColorMode === "dark" ? "https://jafasa-files.s3.us-east-2.amazonaws.com/Jafasa-Logo-letras.png" : "https://jafasa-files.s3.us-east-2.amazonaws.com/Jafasa-Logo-letras.png";

    const wrapperClass = classNames("layout-wrapper", {
        "layout-overlay": layoutMode === "overlay",
        "layout-static": layoutMode === "static",
        'layout-active': sidebarActive,
        "p-input-filled": inputStyle === "filled",
        "p-ripple-disabled": ripple === false,
    });

    const sidebarClassName = classNames("layout-sidebar", {
        "layout-sidebar-dark": layoutColorMode === "dark",
        "layout-sidebar-light": layoutColorMode === "light",
    });

    const { activeUser, cargandoUsuario } = useLogin();

    if (cargandoUsuario) {
        return <Loading />;
    }

    if (!activeUser) {
        return <Login />;
    }


    return (
        <Router>
            <div className={wrapperClass} onClick={onWrapperClick}>
                <AppTopbar onToggleMenu={onToggleMenu} />

                <CSSTransition classNames="layout-sidebar" timeout={{ enter: 120, exit: 100 }} in={isSidebarVisible()} unmountOnExit>

                    <div ref={sidebar} className={sidebarClassName} onClick={onSidebarClick}>

                        {/* <div className="layout-logo" style={{ cursor: "pointer" }} onClick={() => history.push("/")}>
                             <img alt="Logo" src={"data:image/png;base64, " + activeLogin.usuario.cuenta.empresa.logotipo} style={{ width: '93px', height: '25px' }}/> 
                        </div> */}

                        <AppProfile />

                        <AppMenu model={menu} onMenuItemClick={onMenuItemClick} />

                    </div>

                </CSSTransition>

                <div className="layout-main">{activeUser ? <LoginRoutes /> : <LogoutRoutes />}</div>

                <AppFooter />
            </div>
        </Router>
    );
};

function LoginRoutes() {
    return (
        <Switch>
            <Route path="/" exact component={Dashboard} />
            <Route path="/formlayout" component={FormLayoutDemo} />
            <Route path="/input" component={InputDemo} />
            <Route path="/floatlabel" component={FloatLabelDemo} />
            <Route path="/invalidstate" component={InvalidStateDemo} />
            <Route path="/button" component={ButtonDemo} />
            <Route path="/table" component={TableDemo} />
            <Route path="/list" component={ListDemo} />
            <Route path="/tree" component={TreeDemo} />
            <Route path="/panel" component={PanelDemo} />
            <Route path="/overlay" component={OverlayDemo} />
            <Route path="/menu" component={MenuDemo} />
            <Route path="/messages" component={MessagesDemo} />
            <Route path="/file" component={FileDemo} />
            <Route path="/chart" component={ChartDemo} />
            <Route path="/misc" component={MiscDemo} />
            <Route path="/display" component={DisplayDemo} />
            <Route path="/elevation" component={ElevationDemo} />
            <Route path="/flexbox" component={FlexBoxDemo} />
            <Route path="/icons" component={IconsDemo} />
            <Route path="/grid" component={GridDemo} />
            <Route path="/spacing" component={SpacingDemo} />
            <Route path="/typography" component={TypographyDemo} />
            <Route path="/text" component={TextDemo} />
            <Route path="/calendar" component={Calendar} />
            <Route path="/timeline" component={TimelineDemo} />
            <Route path="/crud" component={Crud} />
            <Route path="/empty" component={EmptyPage} />
            <Route path="/documentation" component={Documentation} />
            <Route path="/proveedores" component={Proveedores} />
            <Route path="/operaciones" component={Operaciones} />
            <Route path="/usuarios" component={Usuarios} />
            <Route path="/configuracionUsuario" component={ConfiguracionUsuario} />

        </Switch>
    );
}

function LogoutRoutes() {
    return (
        <Switch>
            <Route path="/login/" component={Login} />
            {/* <Route component={Signup} default /> */}
        </Switch>
    );
}

export default App;
