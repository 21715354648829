import React, { createContext, useReducer, useState, useEffect } from "react";
import LoginReducer from "./LoginReducer";
import axios from "axios";
import {restAPI} from '../../../config/RestApiConfiguration'
import { GET_USER,LOGOUT,SET_COMPANY,SET_LOGIN} from "../types";
import { initAxiosInterceptors, getToken, deleteToken, setToken } from "../../../helpers/auth-helper";

/**
 * Crear contexto
 */

const LoginContext = createContext();
initAxiosInterceptors();

export const LoginProvider = (props) => {
    const [cargandoUsuario, setCargandoUsuario] = useState(true);

    /**
     *  Estado inicial de la aplicacion
     *  Se declara dentro de un objeto todos los datos que
     *  nos interesa obtener del estado
     */

    const initialState = {
        activeUser: null,
        activeCompany: null,
        activeLogin: null
    };

    /**
     *  Use reducer recibe dos parametros,
     *  initialState -> Estado inicial del componente
     *  reducer -> Funciones que se van a ejecutar usando el dispatcher
     */

    const [state, dispatch] = useReducer(LoginReducer, initialState);

    /**
     * Metodos a los que se puede acceder desde el contexto
     */

    const login = async (username,pass) => {
        const res = await axios.post(`${restAPI.SERVER_IP_ADDR}/authenticate`,
            {
                username: username,
                password : pass
            }
          ).then((res) => {

            const { login,token } = res.data;

            setToken(token); 
            setLogin(login);
            setUser(login.usuario);       
            
            });

        //console.log(res);
        
        //setUser();
    };

    const setUser = (usuario) => {
        dispatch({
            type: GET_USER,
            payload: usuario,
        });
    };

    const setCompany = (company) => {
        dispatch({
            type: SET_COMPANY,
            payload: company,
        });
    }

    const setLogin = (login) => {
        dispatch({
            type: SET_LOGIN,
            payload: login,
        });
    }


    const logout = () => {
        deleteToken(null);
        dispatch({
            type: LOGOUT
        });
    };


    useEffect(() => {
        async function cargarUsuario() {
            if (!cargandoUsuario) {
                return;
            }

            if (!getToken()) {
                setCargandoUsuario(false);
                return;
            }

            try {
                setUser();
                setCargandoUsuario(false);
            } catch (error) {
                console.log(error);
            }
        }
        cargarUsuario();
    }, [cargandoUsuario]);

    /**
     *  Se tiene que exportar el context para
     *  que todos los componentes hijos que esten
     *  dentro del context puedan acceder al estado
     *
     *  La propiedad value del provider define a lo que pueden
     *  acceder los hijos
     */

    return (
        <LoginContext.Provider
            value={{
                activeUser: state.activeUser,
                activeCompany: state.activeCompany,
                activeLogin: state.activeLogin,
                login,
                logout,
                cargandoUsuario,
                setCompany
            }}
        >
            {props.children}
        </LoginContext.Provider>
    );
};

export default LoginProvider;

export function useLogin() {
    const context = React.useContext(LoginContext);
    if (!context) {
        throw new Error("useLogin debe de estar dentro del provider");
    }
    return context;
}
