import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { FileUpload } from "primereact/fileupload";
import { Toolbar } from "primereact/toolbar";

import { InputText } from "primereact/inputtext";

import { ProveedorService } from "../service/ProveedorService";

import { OperacionService } from "../service/OperacionService";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { Tag } from 'primereact/tag';
import { useLogin } from "../hooks/context/login/LoginContext";
import { formatoFecha } from "../utilities/formatoFecha";



export const Operaciones = () => {
    const operacionVacio = {
        "idOperacion":'',
        "facturaOperacion":{
           "idFacturaOperacion":'',
           "serie":'',
           "folio":'',
           "uuid":'',
           "idClienteReceptor":'',
           "cliente":{
              "idCliente":'',
              "nombre":'',
              "rfc":'',
              "email":'',
              "telefono":'',
              "idCuenta":'',
              "creationDate":'',
              "lastUpdateDate":'',
              "lastUpdatedBy":'',
              "createdBy":''
           },
           "rfcEmisor":'',
           "razonSocialEmisor":'',
           "creationDate":'',
           "lastUpdateDate":'',
           "lastUpdatedBy":'',
           "createdBy":'',
           "tipoComprobante":'',
           "fechaTimbrado":'',
           "operacionProveedorList":[
              {
                 "idOperacionProveedor":'',
                 "proveedor":{
                    "idProveedor":'',
                    "nombre":'',
                    "rfc":'',
                    "email":'',
                    "telefono":'',
                    "creationDate":'',
                    "idCuenta":'',
                    "hasLogin":'',
                    "lastUpdateDate":'',
                    "lastUpdatedBy":'',
                    "createdBy":''
                 },
                 "creationDate":'',
                 "lastUpdateDate":'',
                 "lastUpdatedBy":'',
                 "createdBy":'',
                 "ordenCompra":''
              }
           ]
        },
        "statusOperacion":'',
        "idCuenta":'',
        "creationDate":'',
        "lastUpdateDate":'',
        "lastUpdatedBy":'',
        "createdBy":''
     };

    const tipoDocumentos = 
        [
            {
                "id_tipo_factura" : 1,
                "tipo_documento" : "Contratos con Clientes"
            },
            {
                "id_tipo_factura" : 2,
                "tipo_documento" : "Contratos con Proveedores"
            },
            {
                "id_tipo_factura" : 3,
                "tipo_documento" : "Facturas"
            },
            {
                "id_tipo_factura" : 4,
                "tipo_documento" : "XML"
            },
            {
                "id_tipo_factura" : 5,
                "tipo_documento" : "Manuales de políticas"
            }
            ,
            {
                "id_tipo_factura" : 6,
                "tipo_documento" : "Manuales de procedimientos"
            }
            ,
            {
                "id_tipo_factura" : 7,
                "tipo_documento" : "Ordenes de compra"
            }
            ,
            {
                "id_tipo_factura" : 8,
                "tipo_documento" : "Pedidos"
            }
            ,
            {
                "id_tipo_factura" : 9,
                "tipo_documento" : "Cotizaciones"
            }
            ,
            {
                "id_tipo_factura" : 10,
                "tipo_documento" : "Entradas y Salidas de almacén"
            }
            ,
            {
                "id_tipo_factura" : 11,
                "tipo_documento" : "Convenios con proveedores"
            }
            ,
            {
                "id_tipo_factura" : 12,
                "tipo_documento" : "E-mail"
            }
            ,
            {
                "id_tipo_factura" : 13,
                "tipo_documento" : "Fotografías"
            }
            ,
            {
                "id_tipo_factura" : 14,
                "tipo_documento" : "Comprobante de Pago"
            }
            ,
            {
                "id_tipo_factura" : 15,
                "tipo_documento" : "Estados de Cuenta"
            }
        ];




    //const [operacionActiva, setOperacionActiva] = useState(null);
    const [proveedoresEmpresa, setProveedoresEmpresa] = useState(null);
    const [operaciones, setOperaciones] = useState(null);
    const [operacion, setOperacion] = useState(null);

    const [selectedOperacion, setSelectedOperacion] = useState(null);
    const [selectedProveedoresEmpresa, setSelectedProveedoresEmpresa] = useState(null);
    const [selectedOperaciones, setSelectedOperaciones] = useState(null);
    const [selectedProveedorOperacion, setSelectedProveedorOperacion] = useState(null);
    const [selectedTipoDocumento, setSelectedTipoDocumento] = useState(null);


    const [archivoSubido, setArchivoSubido] = useState(false);
    const [submitted, setSubmitted] = useState(false);

    const [globalFilter, setGlobalFilter] = useState(null);

    const [deleteOperacionesDialog, setDeleteOperacionesDialog] = useState(null);
    const [deleteOperacionDialog, setDeleteOperacionDialog] = useState(null);
    const [operacionDialog, setOperacionDialog] = useState(false);
    const [detalleOperacionDialog, setDetalleOperacionDialog] = useState(false);
    const [documentosOperacionDialog, setDocumentosOperacionDialog] = useState(false);


    const { activeLogin } = useLogin();

    const [loadingOperaciones,setLoadingOperaciones] = useState(false);

    const toast = useRef(null);
    const dt = useRef(null);
    const dt_conce = useRef(null);

    useEffect(() => {
        setLoadingOperaciones(true);
        const operacionService = new OperacionService();
        const proveedorService = new ProveedorService();

        operacionService.getOperaciones(activeLogin.usuario.cuenta.idCuenta).then((res) => {
            console.log(res.data);
            setOperaciones(res.data);
            proveedorService.getProveedores(activeLogin.usuario.cuenta.idCuenta).then((res) => {
                setProveedoresEmpresa(res.data);
                console.log(res.data);
            });
            
            setLoadingOperaciones(false);
        });

        
    }, []);

    const openNew = () => {
        setOperacion(operacionVacio);
        setSubmitted(false);
        setArchivoSubido(false);
        setOperacionDialog(true);
    };

    const hideDeleteOperacionDialog = () => {
        setDeleteOperacionDialog(false);
    };

    const hideDeleteOperacionesDialog = () => {
        setDeleteOperacionesDialog(false);
    };

    const editDetalleOperacion = (operacionSel) => {
        console.log("data");
        console.log(operacionSel);
        setOperacion({ ...operacionSel });
        setDetalleOperacionDialog(true);
    };

    const editDocumentosOperacion = (proveedorSel) => {
        setSelectedProveedorOperacion({ ...proveedorSel });
        setDocumentosOperacionDialog(true);
    };
    const confirmDeleteOperacion = (operacion) => {
        // setOperacion(operacion);
        setDeleteOperacionDialog(true);
    };

    /*    const deleteOperacion = () => {
        let _products = products.filter((val) => val.id !== product.id);
        setProducts(_products);
        setDeleteProductDialog(false);
        setProduct(emptyProduct);
        toast.current.show({ severity: "success", summary: "Successful", detail: "Product Deleted", life: 3000 });
    };*/

    /* const findIndexById = (id) => {
        let index = -1;
        for (let i = 0; i < products.length; i++) {
            if (products[i].id === id) {
                index = i;
                break;
            }
        }

        return index;
    };*/

    /*  const createId = () => {
        let id = "";
        let chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        for (let i = 0; i < 5; i++) {
            id += chars.charAt(Math.floor(Math.random() * chars.length));
        }
        return id;
    };*/

    const exportCSV = () => {
        dt.current.exportCSV();
    };

    const confirmDeleteSelectedOperaciones = () => {
        setDeleteOperacionesDialog(true);
    };

    /*const deleteSelectedProducts = () => {
        let _products = products.filter((val) => !selectedProducts.includes(val));
        setProducts(_products);
        setDeleteProductsDialog(false);
        setSelectedProducts(null);
        toast.current.show({ severity: "success", summary: "Successful", detail: "Products Deleted", life: 3000 });
    };*/

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="Importar" style={{background:"#007be5"}} icon="pi pi-plus" className="p-button-success p-mr-2" onClick={openNew} />
                <Button label="Eliminar" icon="pi pi-trash" className="p-button-danger" onClick={confirmDeleteSelectedOperaciones} disabled={!selectedOperacion || !selectedOperacion.length} />
            </React.Fragment>
        );
    };

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                {/* <FileUpload mode="basic" accept="image/*" maxFileSize={1000000} label="Importar" chooseLabel="Import" className="p-mr-2 p-d-inline-block" /> */}
                <Button label="Exportar" icon="pi pi-upload" className="p-button-help" onClick={exportCSV} />
            </React.Fragment>
        );
    };

    const accionesOperacionTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success p-mr-2" onClick={() => editDetalleOperacion(rowData)} />
                <Button icon="pi pi-trash" className="p-button-rounded p-button-warning" onClick={() => confirmDeleteOperacion(rowData)} />
            </div>
        );
    };

    const accionesDetallesOperacionTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success p-mr-2" onClick={() => editDocumentosOperacion(rowData)} />
                <Button icon="pi pi-trash" className="p-button-rounded p-button-warning" onClick={() => confirmDeleteOperacion(rowData)} />
            </div>
        );
    };

    const facturaTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Status</span>
                <span className={`product-badge`}>{rowData.facturaOperacion.serie?? '' + rowData.facturaOperacion.folio}</span>
            </>
        );
    };

    const conceptoTemplate = (rowData) => {
        return (
            <>
                <span className={`product-badge`}>{rowData.nombre}</span>
            </>
        );
    };

    const header = (
        <div className="table-header p-d-flex p-jc-center">
            <h5 className="p-m-0">Administrar Operaciones</h5>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Buscar..." />
            </span>
        </div>
    );

    const deleteProductDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteOperacionDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" />
        </>
    );
    const deleteProductsDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteOperacionesDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" />
        </>
    );

    /*  const saveProduct = () => {
        setSubmitted(true);

        if (product.name.trim()) {
            let _products = [...products];
            let _product = { ...product };
            if (product.id) {
                const index = findIndexById(product.id);
                _products[index] = _product;
                toast.current.show({ severity: "success", summary: "Successful", detail: "Product Updated", life: 3000 });
            } else {
                _product.id = createId();
                _product.image = "product-placeholder.svg";
                _products.push(_product);
                toast.current.show({ severity: "success", summary: "Successful", detail: "Product Created", life: 3000 });
            }

            setProducts(_products);
            setProductDialog(false);
            setProduct(emptyProduct);
        }
    };*/

    const hideDialog = () => {
        setSubmitted(false);
        setOperacionDialog(false);
    };

    const hideDialogDetalleOperacion = () => {
        setSubmitted(false);
        setDetalleOperacionDialog(false);
    };

    const hideDialogDocumentosOperacion = () => {
        setSubmitted(false);
        setDocumentosOperacionDialog(false);
    };

    /*  const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || "";
        let _operacion = { ...operacion };
        _operacion[`${name}`] = val;
        setOperacion(_operacion);
    };

    const onInputNumberChange = (e, name) => {
        const val = e.value || 0;
        let _operacion = { ...operacion };
        _operacion[`${name}`] = val;

        setOperacion(_operacion);
    };*/

    const productDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideDialog} disabled={!archivoSubido} />
            <Button label="Save" icon="pi pi-check" className="p-button-text" disabled={!archivoSubido} />
        </>
    );

    const headerDialgOperacion = (
        <div className="p-d-flex p-jc-center">
            <div className="p-mr-2">
                <h3>Agregar Operaciones</h3>
            </div>
        </div>
    );

    const onProveedorEmpresaChange = (e) => {
        setSelectedProveedoresEmpresa(e.value);
    };

    const onTipoDocumentosChange = (e) => {
        setSelectedTipoDocumento(e.value);
    };

    const onBasicUpload = () => {
        setArchivoSubido(true);
        toast.current.show({ severity: "info", summary: "Success", detail: "File Uploaded with Basic Mode" });
    };

    const renderDialogDetalleOperacion = () => {
        if (operacion) {
            return (
                <Dialog maximizable breakpoints={{ "960px": "75vw" }} visible={detalleOperacionDialog} style={{ width: "50vw" }} header={<h3>Operación</h3>} modal className="p-fluid" footer={productDialogFooter} onHide={hideDialogDetalleOperacion}>
                    <div className="p-grid p-fluid ">
                        <div className="p-mb-2 p-col-12">
                            <div style={{fontSize:"20px"}} className="p-mb-2"><span style={{fontWeight:"bold"}} className="p-mr-3"> CLIENTE:</span> {operacion.facturaOperacion.cliente.nombre}</div>
                            <div style={{fontSize:"20px"}} className="p-mb-2"><span style={{fontWeight:"bold"}}> FACTURA:</span> {operacion.facturaOperacion.serie ?? '' + operacion.facturaOperacion.folio}</div>
                            <div style={{fontSize:"20px"}}><span style={{fontWeight:"bold"}} className="p-mr-6"> UUID:</span> {operacion.facturaOperacion.uuid}</div>
                        </div>
                        <div className="p-mb-2 p-col-4">
                            <Dropdown value={selectedProveedoresEmpresa} options={proveedoresEmpresa} onChange={onProveedorEmpresaChange} optionLabel="nombre" filter showClear filterBy="nombre" placeholder="Selecciona proveedor" />
                        </div>
                        <div className="p-mb-2 p-col-4">
                            <InputText placeholder="Orden de Compra" />
                        </div>
                        <div className="p-mb-2 p-col-4">
                            <Button label="Agregar" icon="pi pi-plus" className="p-button-success p-mr-2" style={{background:"#007be5"}} onClick={hideDialog} />
                        </div>
                    </div>

                    <div className="p-grid p-fluid">
                        <div className="p-col-12">
                            <DataTable
                                ref={dt_conce}
                                value={operacion.facturaOperacion.operacionProveedorList}
                                selection={selectedOperacion}
                                onSelectionChange={(e) => setSelectedOperacion(e.value)}
                                dataKey="idOperacionProveedor"
                                paginator
                                rows={10}
                                rowsPerPageOptions={[5, 10, 25]}
                                className="datatable-responsive p-datatable-sm"
                                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} proveedores"
                                globalFilter={globalFilter}
                                emptyMessage="No se encontraron proveedores."
                                header={header}
                                resizableColumns
                                columnResizeMode="fit"
                            >
                                <Column field="proveedor.nombre" header="Proveedor" sortable></Column>
                                <Column  field="ordenCompra" header="Orden de compra" sortable></Column>
                                <Column header="Accion" body={accionesDetallesOperacionTemplate}></Column>
                            </DataTable>
                        </div>
                        
                    </div>
                </Dialog>
            );
        } else {
            setDetalleOperacionDialog(false);
        }
    };

    const onUpload = () => {
        toast.current.show({severity: 'info', summary: 'Success', detail: 'File Uploaded'});
    }

    const onTemplateSelect = (e) => {
        /*let _totalSize = totalSize;
        e.files.forEach(file => {
            _totalSize += file.size;
        });

        setTotalSize(_totalSize);*/
    }

    const onTemplateUpload = (e) => {
        /*let _totalSize = 0;
        e.files.forEach(file => {
            _totalSize += (file.size || 0);
        });*/

        //setTotalSize(_totalSize);
        toast.current.show({severity: 'info', summary: 'Success', detail: 'File Uploaded'});
    }

    const fechaTemplate = (rowData) => {
        return formatoFecha(rowData.facturaOperacion.fechaTimbrado)
    };


    const onTemplateRemove = (file, callback) => {
        //setTotalSize(totalSize - file.size);
        callback();
    }

    const onTemplateClear = () => {
        //setTotalSize(0);
    }
    const fileUploadRef = useRef(null);

    const headerTemplate = (options) => {
        const { className, chooseButton, uploadButton, cancelButton } = options;
       /* const value = totalSize/10000;
        const formatedValue = fileUploadRef && fileUploadRef.current ? fileUploadRef.current.formatSize(totalSize) : '0 B';
*/
        return (
            <div className={className} style={{backgroundColor: 'transparent', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                {chooseButton}
                {uploadButton}
                {cancelButton}
                {/* <ProgressBar value={value} displayValueTemplate={() => `${formatedValue} / 1 MB`} style={{width: '300px', height: '20px', marginLeft: 'auto'}}></ProgressBar> */}
            </div>
        );
    }

    const itemTemplate = (file, props) => {
        return (
            <div className="p-d-flex p-ai-center p-flex-wrap p-jc-between">
                    <div className="p-mr-1" style={{width:"15%"}}>
                        <span className="p-d-flex p-dir-col p-text-left p-ml-3">
                            {file.name}
                            <small>{new Date().toLocaleDateString()}</small>
                        </span>
                    </div>
                    <div className="p-mr-1">
                        <Tag value={props.formatSize} severity="warning" className="p-px-3 p-py-2" />   
                    </div>
                    <div className="p-mr-1">
                        <Button type="button" icon="pi pi-times" className="p-button-outlined p-button-rounded p-button-danger p-ml-auto" onClick={() => onTemplateRemove(file, props.onRemove)} />
                    </div>
            </div>
        )
    }

    const emptyTemplate = () => {
        return (
            <div className="p-d-flex p-ai-center p-dir-col">
                <i className="pi pi-image p-mt-3 p-p-5" style={{'fontSize': '5em', borderRadius: '50%', backgroundColor: 'var(--surface-b)', color: 'var(--surface-d)'}}></i>
                <span style={{'fontSize': '1.2em', color: 'var(--text-color-secondary)'}} className="p-my-5">Drag and Drop Image Here</span>
            </div>
        )
    }

    const chooseOptions = {icon: 'pi pi-fw pi-images', iconOnly: true, className: 'custom-choose-btn p-button-rounded p-button-outlined'};
    const uploadOptions = {icon: 'pi pi-fw pi-cloud-upload', iconOnly: true, className: 'custom-upload-btn p-button-success p-button-rounded p-button-outlined'};
    const cancelOptions = {icon: 'pi pi-fw pi-times', iconOnly: true, className: 'custom-cancel-btn p-button-danger p-button-rounded p-button-outlined'};

    const renderDialogDocumentosOperacion = () => {
        if (selectedProveedorOperacion) {
            return (
                <Dialog maximizable breakpoints={{ "960px": "75vw" }} visible={documentosOperacionDialog} style={{ width: "35vw" }} header={<h3>Documentos operación</h3>} modal className="p-fluid" footer={productDialogFooter} onHide={hideDialogDocumentosOperacion}>
                    <div className=" p-grid p-formgrid p-fluid">
                        <div className="p-mb-2 p-col-4">
                            <Dropdown 
                            value={selectedTipoDocumento} 
                            options={tipoDocumentos} 
                            onChange={onTipoDocumentosChange}
                            optionLabel="tipo_documento" 
                            filter 
                            showClear 
                            filterBy="tipo_documento" 
                            placeholder="Selecciona tipo documento" 
                            />
                        </div>
                        
                        <div className="p-mb-2 p-col-4">
                              <FileUpload
                                    name="xmlOperacion[]"
                                    url="https://primefaces.org/primereact/showcase/upload.php"
                                    onUpload={onBasicUpload}
                                    accept=".xml"
                                    maxFileSize={1000000}
                                    emptyTemplate={<p className="p-m-0">Arrastra y suelta achivos para subirlos.</p>}
                                    chooseLabel="Selecciona"
                                    cancelLabel="Cancelar"
                                    uploadLabel="Subir"
                                    mode="basic"
                                />
                                {/*  <FileUpload ref={fileUploadRef} 
                                    name="demo[]" url="https://primefaces.org/primereact/showcase/upload.php" 
                                    multiple 
                                    accept="image/*" 
                                    maxFileSize={1000000}
                                    onUpload={onTemplateUpload} 
                                    onSelect={onTemplateSelect} 
                                    onError={onTemplateClear} 
                                    onClear={onTemplateClear}
                                    headerTemplate={headerTemplate} 
                                    itemTemplate={itemTemplate} 
                                    emptyTemplate={emptyTemplate}
                                    chooseOptions={chooseOptions} 
                                    uploadOptions={uploadOptions} 
                                    cancelOptions={cancelOptions} 
                                    //contentStyle={{width: "100%", height: "50px"}}
                                    contentClassName = {"Card"}
                                /> */}
                        </div>
                    </div>
                    <div className=" p-grid p-formgrid p-fluid">
                        <div className="p-mb-2 p-col-12">
                                <DataTable
                                    ref={dt_conce}
                                    value={selectedProveedorOperacion.documentos_operacion}
                                    dataKey="id_documento_operacion"
                                    paginator
                                    rows={10}
                                    rowsPerPageOptions={[5, 10, 25]}
                                    className="datatable-responsive p-datatable-sm"
                                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                    currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} proveedores"
                                    globalFilter={globalFilter}
                                    emptyMessage="No se encontraron proveedores."
                                    resizableColumns
                                    columnResizeMode="fit"
                                >
                                    <Column field="tipo_documento.nombre" header="Documento" sortable></Column>
                                    <Column field="creation_date" header="Fecha de creacion" sortable></Column>
                                    <Column header="Accion" body={accionesDetallesOperacionTemplate}></Column>
                                </DataTable>
                            </div>
                    </div>
                </Dialog>
            );
        } else {
            setDocumentosOperacionDialog(false);
        }
    };

    
    const myUploader = async (event) => {
        let files = event.files;
        
        if(files.length !== 0){
            const operacionService = new OperacionService();
            
            for (const file of files) {

                let bodyFormData = new FormData();
                
                bodyFormData.append('file', file);
                
                await operacionService.createOperacion(activeLogin.usuario.cuenta.idCuenta,bodyFormData).then((res) => {
                    toast.current.show({life: 5000,severity: 'success', summary: 'Exito', detail: 'Archivo subido'});
                    console.log(res);
                }).catch(error => {
                    toast.current.show({life: 5000,severity: 'error', summary: 'Fallo', detail: 'Error ' + error.response.data.message});
                });
                
            }
        }
        
        
    }

    return (
        <div className="p-grid crud-demo">
            <div className="p-col-12">
                <div className="card">
                    <Toast ref={toast} />

                    <Toolbar className="p-mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>

                    <DataTable
                        ref={dt}
                        value={operaciones}
                        loading={loadingOperaciones}
                        selection={selectedOperaciones}
                        onSelectionChange={(e) => setSelectedOperaciones(e.value)}
                        dataKey="idOperacion"
                        paginator
                        rows={10}
                        rowsPerPageOptions={[10, 25, 50]}
                        className="datatable-responsive p-datatable-sm"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} proveedores"
                        globalFilter={globalFilter}
                        emptyMessage="No se encontraron operaciones."
                        header={header}
                        resizableColumns
                        columnResizeMode="fit"
                    >
                        <Column selectionMode="multiple" headerStyle={{ width: "3rem" }}></Column>
                        <Column field="facturaOperacion.fechaTimbrado" headerStyle={{ width: "10rem" }} body={fechaTemplate} header="Fecha timbrado" sortable></Column>
                        <Column  field="facturaOperacion.folio" headerStyle={{ width: "8rem" }} header="Factura" sortable body={facturaTemplate}></Column>
                        
                        <Column headerStyle={{ width: "8rem" }}field="facturaOperacion.cliente.rfc" header="RFC Cliente" sortable></Column>
                        <Column field="facturaOperacion.cliente.nombre" header="Cliente" sortable></Column>
                        <Column field="facturaOperacion.uuid" header="Folio Fiscal" sortable></Column>
                        <Column headerStyle={{ width: "4rem" }}field="statusOperacion" header="Status" sortable></Column>
                        
                        <Column header="Accion" body={accionesOperacionTemplate}></Column>
                    </DataTable>

                    <Dialog maximizable breakpoints={{ "960px": "75vw" }} visible={operacionDialog} style={{ width: "50vw" }} header={headerDialgOperacion} modal className="p-fluid" footer={productDialogFooter} onHide={hideDialog}>
                        <div className=" p-grid p-formgrid p-fluid">
                            <div className="p-mb-2 p-col-12">
                                <FileUpload
                                    name="facturaOperacion[]"
                                    uploadHandler={myUploader}
                                    onUpload={onBasicUpload}
                                    multiple
                                    accept=".xml"
                                    maxFileSize={1000000}
                                    emptyTemplate={<p className="p-m-0">Arrastra y suelta achivos para subirlos.</p>}
                                    customUpload={true}
                                    chooseLabel="Selecciona"
                                    cancelLabel="Cancelar"
                                    uploadLabel="Subir"
                                />
                            </div>
                        </div>
                    </Dialog>

                    {operacion && renderDialogDetalleOperacion()}
                    {selectedProveedorOperacion && renderDialogDocumentosOperacion()}

                    <Dialog visible={deleteOperacionDialog} style={{ width: "450px" }} header="Confirm" modal footer={deleteProductDialogFooter} onHide={hideDeleteOperacionDialog}>
                        <div className="confirmation-content">
                            <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: "2rem" }} />
                            {operacion && (
                                <span>
                                    Are you sure you want to delete <b>{operacion.nombre}</b>?
                                </span>
                            )}
                        </div>
                    </Dialog>

                    <Dialog visible={deleteOperacionesDialog} style={{ width: "450px" }} header="Confirm" modal footer={deleteProductsDialogFooter} onHide={hideDeleteOperacionesDialog}>
                        <div className="confirmation-content">
                            <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: "2rem" }} />
                            {operacion && <span>Are you sure you want to delete the selected products?</span>}
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    );
};
