import { Avatar } from "primereact/avatar";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { FileUpload } from "primereact/fileupload";
import { InputSwitch } from "primereact/inputswitch";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Password } from "primereact/password";
import { React } from "react";
import { useLogin } from "../hooks/context/login/LoginContext";

export const ConfiguracionUsuario = () => {
    const { activeUser, activeLogin } = useLogin();

    return (
        <div className="p-d-flex p-d-fluid">
            <div className="p-col-12 p-lg-4 p-m-2">
                <Card className="p-d-flex p-jc-center p-ai-center" style={{ height: "80vh" }}>
                    {/* <Avatar image="https://i.pravatar.cc/300" className="p-mr-2" size="xlarge" shape="circle" />  */}
                    <div className="p-d-flex p-jc-center p-ai-center p-mb-5">
                        {/* <img src="https://i.pravatar.cc/300"  alt="" style={{borderRadius:"50%"}} /> */}
                        <h2>Foto de perfil</h2>
                    </div>

                    <div className="p-d-flex p-jc-center p-ai-center p-mb-5">
                        <img  src={"data:image/png;base64, " + activeUser.foto} alt="" style={{ borderRadius: "50%" }} width="400" height="400"></img>
                    </div>
                    <div className="p-d-flex p-jc-center p-ai-center">
                        <FileUpload mode="basic" chooseLabel="Seleccionar"></FileUpload>
                    </div>
                </Card>
            </div>
            <div className="p-col-12 p-lg-8 p-m-2">
                <Card className="p-d-flex p-jc-center p-ai-center p-mb-5" style={{ height: "100%" }}>
                    <div className="p-d-flex p-jc-center p-ai-center p-mb-5">
                        <h3>{activeLogin.usuario.cuenta.empresa.razonSocial}</h3>
                    </div>

                    <div className="p-d-flex p-jc-center p-ai-center p-mb-5">
                        <h3>Datos de usuario</h3>
                    </div>

                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-md-4">
                            <label htmlFor="nombreUsuario">Nombre</label>
                            <InputText id="nombreUsuario" type="text" value={activeLogin.usuario.nombre} />
                        </div>
                        <div className="p-field p-col-12 p-md-4">
                            <label htmlFor="apellidoPaterno">Apellido Paterno</label>
                            <InputText id="apellidoPaterno" type="text" value={activeLogin.usuario.apellidoPaterno} />
                        </div>
                        <div className="p-field p-col-12 p-md-4">
                            <label htmlFor="apellidoMaterno">Apellido Materno</label>
                            <InputText id="apellidoMaterno" type="text" value={activeLogin.usuario.apellidoMaterno}/>
                        </div>
                        
                        <div className="p-field p-col-12 p-md-6">
                            <label htmlFor="emailLogin">Email</label>
                            <InputText id="emailLogin" type="text" value={activeLogin.email} disabled={true} />
                        </div>

                        <div className="p-field p-col-12 p-md-6">
                            <label htmlFor="passUsuario">Contraseña</label>
                            <Password id="tipoUsuario" />
                        </div>

                        <div className="p-field p-col-12 p-md-6 ">
                            <label htmlFor="emailNotificacion">Email notificacion</label>
                            <InputText id="emailNotificacion" type="text" value={activeLogin.usuario.emailNotificacion}/>
                        </div>
                        <div className="p-field p-col-12 p-md-6">
                            <label htmlFor="tipoUsuario">Tipo Usuario</label>
                            <InputText id="tipoUsuario" type="text" value={activeLogin.usuario.rol.rol} disabled={true} />
                        </div>
                    </div>
                    <div className="p-d-flex p-jc-center p-m-2">
                            <h5 style={{ marginTop: 0 }}>Notificaciones por correo</h5>
                    </div>
                    <div className="p-d-flex p-jc-center p-m-2">
                            <InputSwitch checked={activeLogin.usuario.envioNotificaciones}  />
                    </div>
                    
                    <div className="p-d-flex p-jc-center p-p-2">
                        <Button label="Guardar"></Button>
                    </div>
                </Card>
            </div>
        </div>
    );
};
